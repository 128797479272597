import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import InputGroup from 'react-bootstrap/InputGroup'
import { toast } from 'react-toastify'
import Alert from 'react-bootstrap/Alert'
import { Offcanvas } from 'react-bootstrap'
import { PanelFooter, PanelHeader } from 'components/Panel'
import { toDisplayDate } from 'utils/date'
import api from 'api/api'
import { numberWithSeparator } from 'utils/numberWithSeparator'
import DatosPersonalesUsuario from 'components/DatosPersonalesUsuario/DatosPersonalesUsuario';

function VerUsuario(props) {
	const { usuarioId, setQueMostrar } = props

	const [loading, setLoading] = useState(false)

	const [usuario, setUsuario] = useState(null)
	const datosPersonalesUsuario = {
		id: usuario?.id,
		avatar: usuario?.avatar,
		usuario: usuario?.usuario,
		nombre: usuario?.nombre,
		apellido: usuario?.apellido,
		ci: usuario?.ci,
		telefono: usuario?.telefono,
		fechaRegistro: usuario?.fechaRegistro,
		esProfesional: usuario?.profesional !== false,
		estado: usuario?.estado
	}

	const refreshUsuario = async () => {
		try {
			setLoading(true)
			const { datos } = await api.get('a/usuarios/' + usuarioId)

			setUsuario(datos)
		} catch (error) {
			toast.error('Error de conexión')
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		if (!loading) {
			refreshUsuario()
		}
	}, [])

	const handleClose = () => {
		setQueMostrar('')
	}

	// render
	return (
		<Offcanvas show={true} onHide={handleClose} placement="end" scroll="true" responsive="lg">
			<PanelHeader onClick={handleClose} iconClass="fa-arrow-left" btnText="Atrás" text="Ficha de usuario" />
			<Offcanvas.Body id="ver-usuario">
				{loading ? (
					<Spinner
						animation="border"
						size="lg"
						role="status"
						aria-hidden="true"
						variant="primary"
						className="my-3"
					/>
				) : (
					<Tabs 
						defaultActiveKey="datos-personales"
						className="mb-3"
					>
						<Tab eventKey="datos-personales" title="Datos personales">
							<DatosPersonalesUsuario
								{...datosPersonalesUsuario}
							/>
						</Tab>
						<Tab eventKey="datos-facturacion" title="Datos de facturación">
							Datos de facturación
						</Tab>
						<Tab eventKey="direcciones" title="Direcciones">
							Direcciones
						</Tab>
						<Tab eventKey="pedidos" title="Pedidos">
							Pedidos
						</Tab>
					</Tabs>
				)}

				{/*
				<h1 className="mb-0">Ficha del usuario</h1>
				<div className="mb-4">{toDisplayDate(actualizacion.fecha)}</div>
				<Row>
					<Col md={5} lg={4}>
						<Form.Group controlId="txtBusqueda">
							<InputGroup>
								<Form.Control
									autoFocus
									value={busqueda}
									onChange={(e) => setBusqueda(e.target.value)}
									type="text"
									placeholder="Buscar"
									aria-label="Buscar"
								/>
								<InputGroup.Text><Form.Label className="mb-0"><i className="fa fa-search"></i></Form.Label></InputGroup.Text>
							</InputGroup>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col sm={4}>
						<Table id="ver-actualizacion-general">
							<tbody>
								<tr>
									<th>Productos activados</th>
									<td>{actualizacion.numActivados}</td>
								</tr>
								<tr>
									<th>Productos actualizados</th>
									<td>{actualizacion.numActualizados}</td>
								</tr>
								<tr>
									<th>Productos desactivados</th>
									<td>{actualizacion.numDesactivados}</td>
								</tr>
								<tr>
									<th>Productos nuevos</th>
									<td>{actualizacion.numNuevos}</td>
								</tr>
							</tbody>
						</Table>
					</Col>
					<Col sm={{span: 6, offset: 1}}>
						<Table>
							<tbody>
								<tr>
									<th>Disponibles antes de la actualización</th>
									<td>{numberWithSeparator(actualizacion.numActivosIniciales)}</td>
								</tr>
								<tr>
								<th>Disponibles después de la actualización</th>
									<td>{numberWithSeparator(actualizacion.numActivosFinales)}</td>
								</tr>
								<tr>
									<th>Productos desactivados</th>
									<td>{numberWithSeparator(actualizacion.numDesactivados)}</td>
								</tr>
								<tr>
									<th>Productos no activos por inventario</th>
									<td>{numberWithSeparator(actualizacion.numNoActivosPorInventario)}</td>
								</tr>
								<tr>
									<th>Productos no activos por imagen</th>
									<td>{numberWithSeparator(actualizacion.numNoActivosPorImagen)}</td>
								</tr>
							</tbody>
						</Table>
					</Col>
				</Row>
				*/}
			</Offcanvas.Body >
			<PanelFooter>
				<Button variant="outline-primary" onClick={handleClose} className="me-2">
					Ok
				</Button>
			</PanelFooter>
		</Offcanvas >
	)
}

export default VerUsuario