import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'


function HabilitarProfesional(props) {
	const { profesional, setQueMostrar, habilitar } = props

	const handleClose = () => {
		setQueMostrar('')
	}
	
	return (
		<Modal show={true} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Habilitar Profesional</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Alert variant="success">
					Está a punto de habilitar a <strong>{`${profesional.nombre} ${profesional.apellido}`}</strong>.
				</Alert>
				<p>¿Está seguro que desea continuar?</p>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="outline-tertiary" onClick={handleClose}>
					Cancelar
				</Button>
				<Button variant="success" onClick={() => habilitar(profesional.id)}>
					Habilitar
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default HabilitarProfesional