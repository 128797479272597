import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { toast } from 'react-toastify'

import { PanelFooter, PanelHeader } from 'components/Panel'
import './home.css'

function Home(props) {
	const [showPanel, setShowPanel] = useState(false)

	useEffect(() => {
		toast.success('Inicio!')
	}, [])

	const onClosePanel = () => {
		setShowPanel(false)
	}

	// render
	return (
		<Container fluid id="home" className="vh-100">
			<Row className="d-flex no-gutters">
				<Col xs={12} sm={6} lg={4} className="mt-2">
					<h1>Inicio</h1>
					<hr />
					{/*<Button variant="primary" onClick={() => setShowPanel(true)} className="mt-2">Open sesame</Button>*/}
				</Col>
			</Row>
			<Offcanvas show={showPanel} onHide={onClosePanel} placement="end" scroll="true" responsive="lg">
				<PanelHeader onClick={onClosePanel} iconClass="fa-arrow-left" btnText="Home" text="Panel title" />
				<Offcanvas.Body>
					<p className="mb-0">
						This is content within an <code>.offcanvas-lg</code>.
					</p>
				</Offcanvas.Body>
				<PanelFooter>
					<Button variant="outline-secondary" onClick={onClosePanel}>
						Cancelar
					</Button>
				</PanelFooter>
			</Offcanvas>
		</Container>
	);
}

export default Home