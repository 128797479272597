import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { NO_AVATAR_IMAGE_URL } from 'utils/constants'
import './DatosPersonalesUsuario.css'
import { numberWithSeparator } from 'utils/numberWithSeparator'
import { toDisplayDate } from 'utils/date'
import GoogleMap from 'components/GoogleMap'


function DatosPersonalesUsuario(props) {
	const { id, usuario, nombre, apellido, avatar, ci, telefono, fechaRegistro, estado, fechaSolicitud, fechaAprobacion, esProfesional, ubicacionProfesional } = props

	return (
		<Container>
			<Row>
				<Col>
					<img className="avatar"
						src={avatar?.url ? (process.env.REACT_APP_API_URL + avatar.url) : NO_AVATAR_IMAGE_URL}
						alt={`Avatar`}
					/>
					<div className="nombre">{`${nombre} ${apellido}`}</div>
				</Col>
			</Row>
			<Row>
				<Col>
					<table>
						<tbody>
							{fechaRegistro !== undefined && (
								<tr>
									<th>Fecha registro</th>
									<td>{toDisplayDate(fechaRegistro)}</td>
								</tr>
							)}
							<tr>
								<th>Usuario</th>
								<td>{usuario}</td>
							</tr>
							<tr>
								<th>C.I.</th>
								<td>{ci !== null ? numberWithSeparator(ci) : '-'}</td>
							</tr>
							<tr>
								<th>Teléfono</th>
								<td>{telefono !== null ? telefono : '-'}</td>
							</tr>
							{esProfesional !== undefined ? (
								<tr>
									<th>Profesional</th>
									<td>{esProfesional ? 'Sí' : 'No'}</td>
								</tr>
							) : (
								<>
									{fechaSolicitud !== undefined && (
										<tr>
											<th>Fecha solicitud</th>
											<td>{toDisplayDate(fechaSolicitud)}</td>
										</tr>
									)}
									{estado !== 'pendiente' && fechaAprobacion !== undefined && (
										<tr>
											<th>Fecha aprobación</th>
											<td>{toDisplayDate(fechaAprobacion)}</td>
										</tr>
									)}
								</>
							)}
						</tbody>
					</table>
				</Col>
			</Row>
			<Row>
				<Col>
					{ubicacionProfesional !== undefined ? (
						<div className="mt-3">
							<h4 className="text-start">Ubicación</h4>
							<GoogleMap latitude={parseFloat(ubicacionProfesional.lat)} longitude={parseFloat(ubicacionProfesional.lon)} title="Ubicación del profesional" />
						</div>
					) : null}
				</Col>
			</Row>
		</Container>
	)
}

export default DatosPersonalesUsuario