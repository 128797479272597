import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'


function RechazarProfesional(props) {
	const { profesional, setQueMostrar, rechazar } = props

	const [mensaje, setMensaje] = useState('')

	const handleClose = () => {
		setQueMostrar('')
	}

	return (
		<Modal show={true} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Suspender Profesional</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Col>
						<Alert variant="danger">
							Está a punto de rechazar a <strong>{`${profesional.nombre} ${profesional.apellido}`}</strong>.
						</Alert>
					</Col>
				</Row>
				<Row>
					<Col>
						<Form.Group controlId="txtMensaje" className="mb-0">
							<Form.Label>Mensaje de rechazo</Form.Label>
							<Form.Control as="textarea" rows={4}
								value={mensaje}
								onChange={(e) => setMensaje(e.target.value)}
							/>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col>
						<p className="mt-4">¿Está seguro que desea continuar?</p>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="outline-tertiary" onClick={handleClose}>
					Cancelar
				</Button>
				<Button variant="danger" onClick={() => rechazar(profesional.id, mensaje)}>
					Rechazar
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default RechazarProfesional