import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import { FormLabel } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import { QRCodeCanvas } from 'qrcode.react'
//import QRCode from 'react-qr-code'


function QR() {
	const [texto, setTexto] = useState('')

	const descargarQR = () => {
		console.log('descargando')
		const svg = document.getElementById("QRCode");
		const svgData = new XMLSerializer().serializeToString(svg);
		const canvas = document.createElement("canvas");
		const ctx = canvas.getContext("2d");
		const img = new Image();
		img.onload = () => {
			canvas.width = img.width;
			canvas.height = img.height;
			ctx.drawImage(img, 0, 0);
			const pngFile = canvas.toDataURL("image/png");
			const downloadLink = document.createElement("a");
			downloadLink.download = "QRCode";
			downloadLink.href = `${pngFile}`;
			downloadLink.click();
		};
		img.src = `data:image/svg+xml;base64,${window.btoa(svgData)}`;
		
	};

	// render
	return (
		<Container fluid id="publicidades-bottom-banner" className="vh-100">
			<Row className="d-flex no-gutters">
				<Col xs={12} className="mt-2">
					<h1>QR</h1>
					<hr />
				</Col>
			</Row>
			<Row className="d-flex no-gutters">
				<Col>
					<Form.Group className="mb-3" controlId="input-qr">
						<FormLabel>Generar QR</FormLabel>
						<Form.Control
							value={texto}
							onChange={(e) => setTexto(e.target.value)}
						/>
					</Form.Group>
				</Col>
			</Row>
			<Row className="d-flex no-gutters">
				<Col>
					<div style={{ background: 'white', padding: '16px' }}>
						{/*<QRCode id="QRCode" value={texto} imageSettings={{src:"https://picsum.photos/id/67/300/300", excavate:true}} />*/}
						<QRCodeCanvas id="QRCode" value={texto} size={256} level="H" imageSettings={{ src: "https://ferrex.com.py/logo.png", width: 107, height: 57, excavate: true }} />
					</div>
				</Col>
			</Row>
			<Row className="d-flex no-gutters">
				<Col>
					<Button onClick={() => descargarQR()}>Descargar QR</Button>
				</Col>
			</Row>
		</Container>
	);
}

export default QR