import './Experiencias.css'


function Experiencia(props) {
	const { experiencia } = props

	return (
		<div className="box">
			<div className="lugar">{experiencia.lugar}</div>
			<div className="descripcion">{experiencia.descripcion}</div>
		</div>
	)
}

export default Experiencia