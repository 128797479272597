import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Alert from 'react-bootstrap/Alert'
import { toast } from 'react-toastify'
import Paginacion from 'components/Paginacion'
import api from 'api/api'

import './profesionales.css'
import { DEFAULT_FETCH_CUANTOS, NO_AVATAR_IMAGE_URL } from 'utils/constants'
import { debounce } from 'utils/debounce'
import VerProfesional from './VerProfesional'
import RechazarProfesional from './RechazarProfesional'
import { numberWithSeparator } from 'utils/numberWithSeparator'
import AprobarProfesional from './AprobarProfesional'



function ProfesionalesPendientes(props) {
	const [fetchDeACuantos, setFetchDeACuantos] = useState(DEFAULT_FETCH_CUANTOS)
	const [numResultados, setNumResultados] = useState(0)
	const [paginaAct, setPaginaAct] = useState(1)
	const [loading, setLoading] = useState(false)

	const [busqueda, setBusqueda] = useState('')
	const [filtroBusqueda, setFiltroBusqueda] = useState('')
	const [profesionales, setProfesionales] = useState([])
	const [profesionalActual, setProfesionalActual] = useState()
	const [queMostrar, setQueMostrar] = useState('')

	const opcionesCuantosMostrar = [10, 25, 50]

	const refreshProfesionales = async () => {
		try {
			setLoading(true)
			const { datos } = await api.post('a/ver-profesionales', {
				cuantos: fetchDeACuantos,
				desde: paginaAct === 1 ? 0 : (paginaAct - 1) * fetchDeACuantos,
				filtros: {
					estado: 'pendiente',
					usuario: [filtroBusqueda],
					nombre: [filtroBusqueda],
					apellido: [filtroBusqueda],
					ci: [filtroBusqueda],
					telefono: [filtroBusqueda]
				}
			})

			setProfesionales(datos.profesionales)
			setNumResultados(datos.numResultados)
		} catch (error) {
			toast.error('Error de conexión')
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	const handleBusqueda = (value) => {
		if (value) {
			setFiltroBusqueda(value)
		} else {
			setFiltroBusqueda('')
		}
	}

	const debouceFiltroBusqueda = React.useCallback(
		debounce(handleBusqueda, 400),
		[]
	)

	useEffect(() => {
		debouceFiltroBusqueda(busqueda)
	}, [busqueda])

	useEffect(() => {
		if (paginaAct === 1) {
			refreshProfesionales()
		} else {
			setPaginaAct(1)
		}
	}, [filtroBusqueda, fetchDeACuantos])

	useEffect(() => {
		if (!loading) {
			refreshProfesionales()
		}
	}, [paginaAct])

	const handleAction = (profesional, cual) => {
		setProfesionalActual(profesional, setQueMostrar(cual))
	}

	const aprobarProfesional = async (id) => {
		const { msg } = await api.post('a/profesionales/' + id + '/aprobar')
		refreshProfesionales()
		setQueMostrar('')
		toast.success(msg)
	}

	const rechazarProfesional = async (id, mensaje) => {
		const { msg } = await api.post('a/profesionales/' + id + '/rechazar', { mensaje })
		refreshProfesionales()
		setQueMostrar('')
		toast.success(msg)
	}

	useEffect(() => {
		if (!loading) {
			refreshProfesionales()
		}
	}, [])


	// render
	return (
		<Container fluid id="profesionales" className="vh-100">
			<Row className="d-flex no-gutters">
				<Col xs={12} className="mt-2">
					<h1>Profesionales</h1>
					<hr />
				</Col>
			</Row>
			<Row className="d-flex no-gutters">
				<Col className="mt-2">
					<Form>
						<Row id="searchbar" className="justify-content-between align-items-center">
							<Col sm={6} md={3} style={{ display: 'block' }}>
								<small>
									<span className="d-none d-sm-inline-block">Mostrar de a</span>
									<Form.Group style={{ display: 'inline-block' }}>
										<Form.Select size="sm" onChange={(e) => setFetchDeACuantos(e.target.value)} className="mx-1">
											{opcionesCuantosMostrar.map((o) => {
												return (
													<option value={o} key={`mostrar-${o}`}>
														{o}
													</option>
												)
											})}
										</Form.Select>
									</Form.Group>
									<span className="ms-2">resultados</span>
								</small>
							</Col>
							<Col md={5} lg={4}>
								<Form.Group controlId="txtBusqueda">
									<InputGroup>
										<Form.Control
											autoFocus
											value={busqueda}
											onChange={(e) => setBusqueda(e.target.value)}
											type="text"
											placeholder="Buscar"
											aria-label="Buscar"
										/>
										<InputGroup.Text><Form.Label className="mb-0"><i className="fa fa-search"></i></Form.Label></InputGroup.Text>
									</InputGroup>
								</Form.Group>
							</Col>
						</Row>
					</Form>
				</Col>
			</Row>
			{/* Lista de profesionales */}
			<Row>
				<Col sm={12}>
					{loading ? (
						<Spinner
							animation="border"
							size="lg"
							role="status"
							aria-hidden="true"
							variant="primary"
							className="my-3"
						/>
					) : (
						profesionales.length === 0 ? (
							<Alert variant="info">No se encontraron resultados</Alert>
						) : (
							<Table id="table-profesionales" className="table table-striped">
								<thead>
									<tr>
										<th>Id</th>
										<th>Avatar</th>
										<th>Solicitud</th>
										<th>Usuario</th>
										<th>Apellido</th>
										<th>Nombre</th>
										{/*
										<th>Num. pedidos</th>
										<th>Monto pedidos</th>
										*/}
										<th>Acciones</th>
									</tr>
								</thead>
								<tbody>
									{profesionales.map((profesional) => {
										return (
											<tr key={profesional.id}>
												<td>
													{profesional.id}
												</td>
												<td>
													<img className="list-avatar"
														src={profesional.avatar ? (process.env.REACT_APP_API_URL + profesional.avatar?.url) : NO_AVATAR_IMAGE_URL}
														alt={`Avatar`}
													/>	
												</td>
												<td>
													{profesional.fechaSolicitud}
												</td>
												<td>
													{profesional.email}
												</td>
												<td>
													{profesional.apellido}
												</td>
												<td>
													{profesional.nombre}
												</td>
												{/*
												<td>
													{numberWithSeparator('0')}
												</td>
												<td>
													{numberWithSeparator('0')}
												</td>
												*/}
												<td>
													<DropdownButton
														as={ButtonGroup}
														title={<i className="fa fa-ellipsis-v"></i>}
														drop="end"
													>
														<Dropdown.Item eventKey="1" onClick={() => handleAction(profesional, 'ver')}><i className="fa fa-eye"></i> Ver</Dropdown.Item>
														<Dropdown.Item eventKey="2" onClick={() => handleAction(profesional, 'aprobar')}><i className="fa fa-check"></i> Aprobar</Dropdown.Item>
														<Dropdown.Item eventKey="3" onClick={() => handleAction(profesional, 'rechazar')} style={{color: 'var(--bs-danger)'}} ><i className="fa fa-user-lock"></i> Rechazar</Dropdown.Item>
													</DropdownButton>
												</td>
											</tr>
										)
									})}
								</tbody>
							</Table>
						)
					)}
					<Col>
						<Paginacion
							numResultados={numResultados}
							pagina={paginaAct}
							setPagina={setPaginaAct}
							deACuantos={fetchDeACuantos}
						/>
					</Col>
				</Col>
			</Row>

			{/* Panel Ver */}
			{
				profesionalActual && queMostrar === 'ver' &&
				<VerProfesional
					profesionalId={profesionalActual.id}
					setQueMostrar={setQueMostrar}
				/>
			}

			{/* Modal aprobar */}
			{
				profesionalActual && queMostrar === 'aprobar' &&
				<AprobarProfesional
					key={profesionalActual.id}
					profesional={profesionalActual}
					setQueMostrar={setQueMostrar}
					aprobar={aprobarProfesional}
				/>
			}

			{/* Modal rechazar */}
			{
				profesionalActual && queMostrar === 'rechazar' &&
				<RechazarProfesional
					key={profesionalActual.id}
					profesional={profesionalActual}
					setQueMostrar={setQueMostrar}
					rechazar={rechazarProfesional}
				/>
			}
		</Container >
	);
}

export default ProfesionalesPendientes