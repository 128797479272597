import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Alert from 'react-bootstrap/Alert'
import { toast } from 'react-toastify'
import Paginacion from 'components/Paginacion'
import api from 'api/api'

import './usuarios.css'
import { DEFAULT_FETCH_CUANTOS, NO_AVATAR_IMAGE_URL } from 'utils/constants'
import { debounce } from 'utils/debounce'
import VerUsuario from './VerUsuario'
import SuspenderUsuario from './SuspenderUsuario'
import { numberWithSeparator } from 'utils/numberWithSeparator'



function Usuarios(props) {
	const [fetchDeACuantos, setFetchDeACuantos] = useState(DEFAULT_FETCH_CUANTOS)
	const [numResultados, setNumResultados] = useState(0)
	const [paginaAct, setPaginaAct] = useState(1)
	const [loading, setLoading] = useState(false)

	const [busqueda, setBusqueda] = useState('')
	const [filtroBusqueda, setFiltroBusqueda] = useState('')
	const [usuarios, setUsuarios] = useState([])
	const [usuarioActual, setUsuarioActual] = useState()
	const [queMostrar, setQueMostrar] = useState('')

	const opcionesCuantosMostrar = [10, 25, 50]

	const refreshUsuarios = async () => {
		try {
			setLoading(true)
			const { datos } = await api.post('a/ver-usuarios', {
				cuantos: fetchDeACuantos,
				desde: paginaAct === 1 ? 0 : (paginaAct - 1) * fetchDeACuantos,
				filtros: {
					usuario: [filtroBusqueda],
					nombre: [filtroBusqueda],
					apellido: [filtroBusqueda],
					ci: ['?'],
					telefono: ['!']
				}
			})

			setUsuarios(datos.usuarios)
			setNumResultados(datos.numResultados)
		} catch (error) {
			toast.error('Error de conexión')
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	const handleBusqueda = (value) => {
		if (value) {
			setFiltroBusqueda(value)
		} else {
			setFiltroBusqueda('')
		}
	}

	const debouceFiltroBusqueda = React.useCallback(
		debounce(handleBusqueda, 400),
		[]
	)

	useEffect(() => {
		debouceFiltroBusqueda(busqueda)
	}, [busqueda])

	useEffect(() => {
		if (paginaAct === 1) {
			refreshUsuarios()
		} else {
			setPaginaAct(1)
		}
	}, [filtroBusqueda, fetchDeACuantos])

	useEffect(() => {
		if (!loading) {
			refreshUsuarios()
		}
	}, [paginaAct])

	const handleAction = (usuario, cual) => {
		setUsuarioActual(usuario, setQueMostrar(cual))
	}

	const suspenderUsuario = async (id) => {
		const { msg } = await api.delete('a/usuarios/' + id + '/suspender')
		refreshUsuarios()
		setQueMostrar('')
		toast.success(msg)
	}

	useEffect(() => {
		if (!loading) {
			refreshUsuarios()
		}
	}, [])


	// render
	return (
		<Container fluid id="usuarios" className="vh-100">
			<Row className="d-flex no-gutters">
				<Col xs={12} className="mt-2">
					<h1>Usuarios</h1>
					<hr />
				</Col>
			</Row>
			<Row className="d-flex no-gutters">
				<Col className="mt-2">
					<Form>
						<Row id="searchbar" className="justify-content-between align-items-center">
							<Col sm={6} md={3} style={{ display: 'block' }}>
								<small>
									<span className="d-none d-sm-inline-block">Mostrar de a</span>
									<Form.Group style={{ display: 'inline-block' }}>
										<Form.Select size="sm" onChange={(e) => setFetchDeACuantos(e.target.value)} className="mx-1">
											{opcionesCuantosMostrar.map((o) => {
												return (
													<option value={o} key={`mostrar-${o}`}>
														{o}
													</option>
												)
											})}
										</Form.Select>
									</Form.Group>
									<span className="ms-2">resultados</span>
								</small>
							</Col>
							<Col md={5} lg={4}>
								<Form.Group controlId="txtBusqueda">
									<InputGroup>
										<Form.Control
											autoFocus
											value={busqueda}
											onChange={(e) => setBusqueda(e.target.value)}
											type="text"
											placeholder="Buscar"
											aria-label="Buscar"
										/>
										<InputGroup.Text><Form.Label className="mb-0"><i className="fa fa-search"></i></Form.Label></InputGroup.Text>
									</InputGroup>
								</Form.Group>
							</Col>
						</Row>
					</Form>
				</Col>
			</Row>
			{/* Lista de usuarios */}
			<Row>
				<Col sm={12}>
					{loading ? (
						<Spinner
							animation="border"
							size="lg"
							role="status"
							aria-hidden="true"
							variant="primary"
							className="my-3"
						/>
					) : (
						usuarios.length === 0 ? (
							<Alert variant="info">No se encontraron resultados</Alert>
						) : (
							<Table id="table-usuarios" className="table table-striped">
								<thead>
									<tr>
										<th>Id</th>
										<th>Avatar</th>
										<th>Registro</th>
										<th>Usuario</th>
										<th>Apellido</th>
										<th>Nombre</th>
										{/*
										<th>Num. pedidos</th>
										<th>Monto pedidos</th>
										*/}
										<th>Acciones</th>
									</tr>
								</thead>
								<tbody>
									{usuarios.map((usuario) => {
										return (
											<tr key={usuario.id} className={usuario.via === 'fix' ? "resaltado" : null}>
												<td>
													{usuario.id}
												</td>
												<td>
													<img className="list-avatar"
														src={usuario.avatar ? process.env.REACT_APP_API_URL + usuario.avatar?.url : NO_AVATAR_IMAGE_URL}
														alt={`Avatar`}
													/>
												</td>
												<td>
													{usuario.fechaRegistro}
												</td>
												<td>
													{usuario.usuario}
												</td>
												<td>
													{usuario.apellido}
												</td>
												<td>
													{usuario.nombre}
												</td>
												{/*
												<td>
													{numberWithSeparator('0')}
												</td>
												<td>
													{numberWithSeparator('0')}
												</td>
												*/}
												<td>
													<DropdownButton
														as={ButtonGroup}
														title={<i className="fa fa-ellipsis-v"></i>}
														drop="end"
													>
														<Dropdown.Item eventKey="1" onClick={() => handleAction(usuario, 'ver')}><i className="fa fa-eye"></i> Ver</Dropdown.Item>
														<Dropdown.Item eventKey="2" onClick={() => handleAction(usuario, 'suspender')} variant="danger"><i className="fa fa-user-lock"></i> Suspender</Dropdown.Item>
													</DropdownButton>
												</td>
											</tr>
										)
									})}
								</tbody>
							</Table>
						)
					)}
					<Col>
						<Paginacion
							numResultados={numResultados}
							pagina={paginaAct}
							setPagina={setPaginaAct}
							deACuantos={fetchDeACuantos}
						/>
					</Col>
				</Col>
			</Row>

			{/* Panel Ver */}
			{
				usuarioActual && queMostrar === 'ver' &&
				<VerUsuario
					usuarioId={usuarioActual.id}
					setQueMostrar={setQueMostrar}
				/>
			}

			{/* Modal suspender */}
			{
				usuarioActual && queMostrar === 'suspender' &&
				<SuspenderUsuario
					key={usuarioActual.id}
					usuario={usuarioActual}
					setQueMostrar={setQueMostrar}
					suspender={suspenderUsuario}
				/>
			}
		</Container >
	);
}

export default Usuarios