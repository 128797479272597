import './Servicios.css'


function Servicio(props) {
	const { servicio } = props

	return (
		<div className="box">
			<div className="categoria">{servicio.categoriaServicio}</div>
			<div className="descripcion">{servicio.descripcion}</div>
		</div>
	)
}

export default Servicio