import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Alert from 'react-bootstrap/Alert'
import { toast } from 'react-toastify'
import Paginacion from 'components/Paginacion'
import api from 'api/api'

import './actualizaciones.css'
import { DEFAULT_FETCH_CUANTOS } from 'utils/constants'
import { debounce } from 'utils/debounce'
import VerActualizacion from './VerActualizacion'
import EliminarActualizacion from './EliminarActualizacion'
import { numberWithSeparator } from 'utils/numberWithSeparator'


function Actualizaciones(props) {
	const [fetchDeACuantos, setFetchDeACuantos] = useState(DEFAULT_FETCH_CUANTOS)
	const [numResultados, setNumResultados] = useState(0)
	const [paginaAct, setPaginaAct] = useState(1)
	const [loading, setLoading] = useState(false)

	const [busqueda, setBusqueda] = useState('')
	const [filtroBusqueda, setFiltroBusqueda] = useState('')
	const [actualizaciones, setActualizaciones] = useState([])
	const [actualizacionActual, setActualizacionActual] = useState()
	const [queMostrar, setQueMostrar] = useState('')

	const opcionesCuantosMostrar = [10, 25, 50]

	const refreshConfig = async () => {
		try {
			const { datos } = await api.get('a/config-actualizaciones')
			// TODO: por si trae algo en el futuro
		} catch (error) {
			toast.error('Error ')
		}
	}

	const refreshActualizaciones = async () => {
		try {
			setLoading(true)
			const { datos } = await api.post('a/ver-actualizaciones', {
				cuantos: fetchDeACuantos,
				desde: paginaAct === 1 ? 0 : (paginaAct - 1) * fetchDeACuantos,
				filtros: {}
			})

			setActualizaciones(datos.actualizaciones)
			setNumResultados(datos.numResultados)
		} catch (error) {
			toast.error('Error de conexión')
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	const handleBusqueda = (value) => {
		if (value) {
			setFiltroBusqueda(value)
		} else {
			setFiltroBusqueda('')
		}
	}

	const debouceFiltroBusqueda = React.useCallback(
		debounce(handleBusqueda, 400),
		[]
	)

	useEffect(() => {
		debouceFiltroBusqueda(busqueda)
	}, [busqueda])

	useEffect(() => {
		if (paginaAct === 1) {
			refreshActualizaciones()
		} else {
			setPaginaAct(1)
		}
	}, [filtroBusqueda, fetchDeACuantos])

	useEffect(() => {
		if (!loading) {
			refreshActualizaciones()
		}
	}, [paginaAct])

	const handleAction = (actualizacion, cual) => {
		setActualizacionActual(actualizacion, setQueMostrar(cual))
	}

	const actualizacionManual = async (data) => {
		try {
			const { msg } = await api.post('a/actualizaciones', {})
			toast.success(msg)
		} catch (error) {
			console.error(error)
			toast.error('Error de conexión')
		}
	}

	const eliminarActualizacion = async (id) => {
		const { msg } = await api.delete('a/actualizaciones/' + id)
		refreshConfig()
		refreshActualizaciones()
		setQueMostrar('')
		toast.success(msg)
	}

	useEffect(() => {
		if (!loading) {
			refreshConfig()
			refreshActualizaciones()
		}
	}, [])


	// render
	return (
		<Container fluid id="actualizaciones" className="vh-100">
			<Row className="d-flex no-gutters">
				<Col xs={12} className="mt-2">
					<h1>Actualizaciones</h1>
					<hr />
				</Col>
			</Row>
			<Row className="d-flex no-gutters">
				<Col className="mt-2">
					<Form>
						<Row id="searchbar" className="justify-content-between align-items-center">
							<Col sm={6} md={3} style={{ display: 'block' }}>
								<small>
									<span className="d-none d-sm-inline-block">Mostrar de a</span>
									<Form.Group style={{ display: 'inline-block' }}>
										<Form.Select size="sm" onChange={(e) => setFetchDeACuantos(e.target.value)} className="mx-1">
											{opcionesCuantosMostrar.map((o) => {
												return (
													<option value={o} key={`mostrar-${o}`}>
														{o}
													</option>
												)
											})}
										</Form.Select>
									</Form.Group>
									<span className="ms-2">resultados</span>
								</small>
							</Col>
							<Col md={5} lg={4}>
								<Form.Group controlId="txtBusqueda">
									<InputGroup>
										<Form.Control
											autoFocus
											value={busqueda}
											onChange={(e) => setBusqueda(e.target.value)}
											type="text"
											placeholder="Buscar"
											aria-label="Buscar"
										/>
										<InputGroup.Text><Form.Label className="mb-0"><i className="fa fa-search"></i></Form.Label></InputGroup.Text>
									</InputGroup>
								</Form.Group>
							</Col>
						</Row>
					</Form>
				</Col>
			</Row>
			<Row className="mb-3">
				<Col>
					<Button variant="primary" onClick={() => actualizacionManual()} className="mt-2"><i className="fa fa-plus"></i> Actualización manual</Button>
				</Col>
			</Row>
			{/* Lista actualizaciones */}
			<Row>
				<Col sm={12}>
					{loading ? (
						<Spinner
							animation="border"
							size="lg"
							role="status"
							aria-hidden="true"
							variant="primary"
							className="my-3"
						/>
					) : (
						actualizaciones.length === 0 ? (
							<Alert variant="info">No se encontraron resultados</Alert>
						) : (
							<Table className="table table-striped">
								<thead>
									<tr>
										<th>Id</th>
										<th>Fecha</th>
										<th>Activados</th>
										<th>Actualizados</th>
										<th>Desactivados</th>
										<th>Nuevos</th>
										<th>Activos finales</th>
										<th>Acciones</th>
									</tr>
								</thead>
								<tbody>
									{actualizaciones.map((actualizacion) => {
										return (
											<tr key={actualizacion.id}>
												<td>
													{actualizacion.id}
												</td>
												<td>
													{actualizacion.fecha}
												</td>
												<td>
													{numberWithSeparator(actualizacion.numActivados)}
												</td>
												<td>
													{numberWithSeparator(actualizacion.numActualizados)}
												</td>
												<td>
													{numberWithSeparator(actualizacion.numDesactivados)}
												</td>
												<td>
													{numberWithSeparator(actualizacion.numNuevos)}
												</td>
												<td>
													{numberWithSeparator(actualizacion.numActivosFinales)}
												</td>
												<td>
													<DropdownButton
														as={ButtonGroup}
														title={<i className="fa fa-ellipsis-v"></i>}
														drop="end"
													>
														<Dropdown.Item eventKey="1" onClick={() => handleAction(actualizacion, 'ver')}><i className="fa fa-eye"></i> Ver</Dropdown.Item>
														<Dropdown.Item eventKey="2" onClick={() => handleAction(actualizacion, 'eliminar')} variant="danger"><i className="fa fa-trash"></i> Eliminar</Dropdown.Item>
													</DropdownButton>
												</td>
											</tr>
										)
									})}
								</tbody>
							</Table>
						)
					)}
					<Col>
						<Paginacion
							numResultados={numResultados}
							pagina={paginaAct}
							setPagina={setPaginaAct}
							deACuantos={fetchDeACuantos}
						/>
					</Col>
				</Col>
			</Row>

			{/* Panel Ver */}
			{
				actualizacionActual && queMostrar === 'ver' &&
				<VerActualizacion
					actualizacion={actualizacionActual}
					setQueMostrar={setQueMostrar}
				/>
			}

			{/* Modal Delete */}
			{
				actualizacionActual && queMostrar === 'eliminar' &&
				<EliminarActualizacion
					key={actualizacionActual.id}
					actualizacion={actualizacionActual}
					setQueMostrar={setQueMostrar}
					eliminar={eliminarActualizacion}
				/>
			}
		</Container >
	);
}

export default Actualizaciones