import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import InputGroup from 'react-bootstrap/InputGroup'
import { toast } from 'react-toastify'
import Alert from 'react-bootstrap/Alert'
import { Offcanvas } from 'react-bootstrap'
import { PanelFooter, PanelHeader } from 'components/Panel'
import { toDisplayDate } from 'utils/date'
import { debounce } from 'utils/debounce'
import Paginacion from 'components/Paginacion'
import api from 'api/api'
import { DEFAULT_FETCH_CUANTOS } from 'utils/constants'
import { numberWithSeparator } from 'utils/numberWithSeparator'

function VerActualizacion(props) {
	const { actualizacion, setQueMostrar } = props

	const [fetchDeACuantos, setFetchDeACuantos] = useState(DEFAULT_FETCH_CUANTOS)
	const [numResultados, setNumResultados] = useState(0)
	const [paginaAct, setPaginaAct] = useState(1)
	const [loading, setLoading] = useState(false)

	const [busqueda, setBusqueda] = useState('')
	const [filtroBusqueda, setFiltroBusqueda] = useState('')

	const [productos, setProductos] = useState([])

	const refreshProductos = async () => {
		try {
			setLoading(true)
			const { datos } = await api.post('a/actualizaciones/' + actualizacion.id + '/ver-productos', {
				cuantos: fetchDeACuantos,
				desde: paginaAct === 1 ? 0 : (paginaAct - 1) * fetchDeACuantos,
				filtros: {
					codigo: busqueda
				}
			})

			setProductos(datos.productos)
			setNumResultados(datos.numResultados)
		} catch (error) {
			toast.error('Error de conexión')
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	const handleBusqueda = (value) => {
		if (value) {
			setFiltroBusqueda(value)
		} else {
			setFiltroBusqueda('')
		}
	}

	const debouceFiltroBusqueda = React.useCallback(
		debounce(handleBusqueda, 400),
		[]
	)

	useEffect(() => {
		debouceFiltroBusqueda(busqueda)
	}, [busqueda])

	useEffect(() => {
		if (paginaAct === 1) {
			refreshProductos()
		} else {
			setPaginaAct(1)
		}
	}, [filtroBusqueda, fetchDeACuantos])

	useEffect(() => {
		if (!loading) {
			refreshProductos()
		}
	}, [paginaAct])

	const handleClose = () => {
		setQueMostrar('')
	}

	// render
	return (
		<Offcanvas show={true} onHide={handleClose} placement="end" scroll="true" responsive="lg">
			<PanelHeader onClick={handleClose} iconClass="fa-arrow-left" btnText="Atrás" text="Ver Actualización" />
			<Offcanvas.Body id="ver-actualizacion">
				<h1 className="mb-0">Elementos de la actualización</h1>
				<div className="mb-4">{toDisplayDate(actualizacion.fecha)}</div>
				<Row>
					<Col md={5} lg={4}>
						<Form.Group controlId="txtBusqueda">
							<InputGroup>
								<Form.Control
									autoFocus
									value={busqueda}
									onChange={(e) => setBusqueda(e.target.value)}
									type="text"
									placeholder="Buscar"
									aria-label="Buscar"
								/>
								<InputGroup.Text><Form.Label className="mb-0"><i className="fa fa-search"></i></Form.Label></InputGroup.Text>
							</InputGroup>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col sm={4}>
						<Table id="ver-actualizacion-general">
							<tbody>
								<tr>
									<th>Productos activados</th>
									<td>{actualizacion.numActivados}</td>
								</tr>
								<tr>
									<th>Productos actualizados</th>
									<td>{actualizacion.numActualizados}</td>
								</tr>
								<tr>
									<th>Productos desactivados</th>
									<td>{actualizacion.numDesactivados}</td>
								</tr>
								<tr>
									<th>Productos nuevos</th>
									<td>{actualizacion.numNuevos}</td>
								</tr>
							</tbody>
						</Table>
					</Col>
					<Col sm={{span: 6, offset: 1}}>
						<Table>
							<tbody>
								<tr>
									<th>Disponibles antes de la actualización</th>
									<td>{numberWithSeparator(actualizacion.numActivosIniciales)}</td>
								</tr>
								<tr>
								<th>Disponibles después de la actualización</th>
									<td>{numberWithSeparator(actualizacion.numActivosFinales)}</td>
								</tr>
								<tr>
									<th>Productos desactivados</th>
									<td>{numberWithSeparator(actualizacion.numDesactivados)}</td>
								</tr>
								<tr>
									<th>Productos no activos por inventario</th>
									<td>{numberWithSeparator(actualizacion.numNoActivosPorInventario)}</td>
								</tr>
								<tr>
									<th>Productos no activos por imagen</th>
									<td>{numberWithSeparator(actualizacion.numNoActivosPorImagen)}</td>
								</tr>
							</tbody>
						</Table>
					</Col>
				</Row>
				{/* Lista productos */}
				<Row>
					<Col sm={12}>
						{loading ? (
							<Spinner
								animation="border"
								size="lg"
								role="status"
								aria-hidden="true"
								variant="primary"
								className="my-3"
							/>
						) : (
							productos.length === 0 ? (
								<Alert variant="info">No se encontraron resultados</Alert>
							) : (
								<>
									<Table className="table table-striped">
										<thead>
											<tr>
												<th>SKU</th>
												<th>Nombre</th>
												<th>Disponible</th>
												<th>Precio</th>
												<th>conImagen</th>
											</tr>
										</thead>
										<tbody>
											{productos.map((producto) => {
												return (
													<tr key={producto.sku}>
														<td>
															{producto.sku}
														</td>
														<td>
															{producto.descripcion === null ? '-' : producto.descripcion}
														</td>
														<td>
															{producto.disponible === null ? '-' : producto.disponible}
														</td>
														<td>
															{producto.precio === null ? '-' : numberWithSeparator(producto.precio)}
														</td>
														<td>
															{producto.conImagen === '1' ? <i className="fa fa-check" style={{color: "green"}}></i> : <i className="fa fa-times" style={{color: "red"}}></i>}
														</td>
													</tr>
												)
											})}
										</tbody>
									</Table>
									<Col>
										<Paginacion
											numResultados={numResultados}
											pagina={paginaAct}
											setPagina={setPaginaAct}
											deACuantos={fetchDeACuantos}
										/>
									</Col>
								</>
							)
						)}
					</Col>
				</Row>
			</Offcanvas.Body >
			<PanelFooter>
				<Button variant="outline-primary" onClick={handleClose} className="me-2">
					Ok
				</Button>
			</PanelFooter>
		</Offcanvas >
	)
}

export default VerActualizacion