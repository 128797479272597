import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'


function AprobarProfesional(props) {
	const { profesional, setQueMostrar, aprobar } = props

	const handleClose = () => {
		setQueMostrar('')
	}

	return (
		<Modal show={true} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Aprobar Profesional</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Col>
						<Alert variant="success">
							Está a punto de aprobar a <strong>{`${profesional.nombre} ${profesional.apellido}`}</strong>.
						</Alert>
					</Col>
				</Row>
				<Row>
					<Col>
						<p className="mt-4">¿Está seguro que desea continuar?</p>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="outline-tertiary" onClick={handleClose}>
					Cancelar
				</Button>
				<Button variant="success" onClick={() => aprobar(profesional.id)}>
					Aprobar
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default AprobarProfesional