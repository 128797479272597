import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import api from 'api/api'
import { toast } from 'react-toastify'

import './actualizaciones.css'


function ActualizacionesLog(props) {
	const [loading, setLoading] = useState(false)
	const [log, setLog] = useState('')

	const refreshLog = async () => {
		try {
			setLoading(true)
			const { datos } = await api.get('a/ver-log-actualizaciones')

			setLog(datos)
		} catch (error) {
			toast.error('Error de conexión')
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		if (!loading) {
			refreshLog()
		}
	}, [])


	// render
	return (
		<Container fluid id="actualizaciones-log" className="vh-100">
			<Row className="d-flex no-gutters">
				<Col xs={12} className="mt-2">
					<h1>Log</h1>
					<hr />
				</Col>
			</Row>
			{loading ? (
				<Spinner
					animation="border"
					size="lg"
					role="status"
					aria-hidden="true"
					variant="primary"
					className="my-3"
				/>
			) : (
				<Row>
					<Col>
						<pre>{log}</pre>
					</Col>
				</Row >
			)}
		</Container >
	)
}

export default ActualizacionesLog