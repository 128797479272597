import { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import api from 'api/api'
import { toast } from 'react-toastify'

import FileUpload from 'components/FileUpload/FileUpload'


function TestFileUpload(props) {
	const [data, setData] = useState({
		metodoPago: 'transferencia',
		fecha: '2022-09-01',
		files: []
	})

	const updateUploadedFiles = (files) => {
    	setData({ ...data, files: files })
	}

	const  handleSubmit = async(event) => {
		event.preventDefault()

		const serverData = new FormData();
		serverData.append('comprobante', data.files[0])
		serverData.append('metodoPago', data.metodoPago)
		serverData.append('fecha', data.fecha)

		const { msg } = await api.post('a/pagos-recibidos', serverData)
		toast.success(msg)

		console.log('submitting')
	}

	

	return (
		<Container fluid id="test" className="vh-100">
			<Row className="d-flex no-gutters">
				<Col className="mt-2">
					<form onSubmit={handleSubmit}>
						<FileUpload
							updateFiles={updateUploadedFiles}
							title="Profile Image(s)"
							accept=".jpg,.png,.jpeg"
						/>
						<button type="submit">Fire in the hole!</button>
					</form>
				</Col>
			</Row>
		</Container>
	);
}

export default TestFileUpload