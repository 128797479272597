import './Cursos.css'


function Curso(props) {
	const { curso } = props

	return (
		<div className="box">
			<div className="nombreCurso">{curso.nombre}</div>
			<div className="institucion">{curso.institucion}</div>
		</div>
	)
}

export default Curso