import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'


function EliminarActualizacion(props) {
	const { actualizacion, setQueMostrar, eliminar } = props

	const handleClose = () => {
		setQueMostrar('')
	}
	
	return (
		<Modal show={true} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Eliminar Actualización</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Alert variant="danger">
					Está a punto de eliminar <strong>{`${actualizacion.fecha}`}</strong>. Esta acción no se puede deshacer.
				</Alert>
				<p>¿Está seguro que desea continuar?</p>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="outline-tertiary" onClick={handleClose}>
					Cancelar
				</Button>
				<Button variant="danger" onClick={() => eliminar(actualizacion.id)}>
					Eliminar
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default EliminarActualizacion