import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import Table from 'react-bootstrap/Table'
import Alert from 'react-bootstrap/Alert'
import { toast } from 'react-toastify'

import api from 'api/api'

import './productos-imagenes.css'
import { numberWithSeparator } from 'utils/numberWithSeparator'

function ProductosImagenes(props) {
	const [loading, setLoading] = useState(false)
	const [totales, setTotales] = useState(false)

	useEffect(() => {
		if (!loading) {
			refreshTotales()
		}
	}, [])

	const refreshTotales = async () => {
		try {
			const { datos } = await api.get('a/reportes/totales-productos-imagenes')
			setTotales(datos)
			console.log('totales', totales)
		} catch (error) {
			toast.error('Error ')
		}
	}

	// render
	return (
		<Container fluid id="control-imagenes" className="vh-100">
			<Row className="d-flex no-gutters">
				<Col xs={12} sm={6} lg={4} className="mt-2">
					<h1>Control de imágenes</h1>
					<hr />
				</Col>
			</Row>
			<Row>
				<Col>
					{loading || !totales ? (
						<Spinner
							animation="border"
							size="lg"
							role="status"
							aria-hidden="true"
							variant="primary"
							className="my-3"
						/>
					) : (
						<>
							<Table>
								<tbody>
									<tr>
										<th>Cantidad total productos</th>
										<td>{numberWithSeparator(totales.totalItems)}</td>
									</tr>
									<tr>
										<th>Total con imágenes</th>
										<td>{numberWithSeparator(totales.totalConImagenes)}</td>
									</tr>
									<tr>
										<th>Cantidad de disponibles</th>
										<td>{numberWithSeparator(totales.totalActivos)}</td>
									</tr>
								</tbody>
							</Table>
							<a variant="primary" href={process.env.REACT_APP_API_URL + 'a/reportes/ver-productos-sin-imagen'} className="mt-2" target="_blank" rel="noreferrer">Ver productos sin imagen</a>
							<Alert className="mt-3" variant="info">
								El archivo de productos sin imagen contiene todos los productos en la base de datos para los cuales no tenemos imagen.<br/>
								Esto no significa que teniendo la imagen, los productos estarán activos. Dependerá de las reglas de disponibilidad.
							</Alert>
						</>
					)}
				</Col>
			</Row>
		</Container>
	);
}

export default ProductosImagenes