import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './Servicios.css'
import Servicio from './Servicio'


function Servicios(props) {
	const { servicios } = props

	return (
		<Container fluid>
			<Row>
				<Col>
					{servicios?.map(servicio => {
						return (
							<Servicio key={servicio.id} servicio={servicio} />
						)
					})}
				</Col>
			</Row>
		</Container>
	)
}

export default Servicios