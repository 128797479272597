import React from 'react';
import GoogleMapReact from 'google-map-react';

const GoogleMaps = (props) => {
	const { latitude, longitude, title, zoom = 14 } = props

	const GOOGLE_MAPS_API_KEY = 'AIzaSyD3GCG8S_5eLqsH71q6_BZmCPldNQctb1Q'

	const center = {
		lat: latitude,
		lng: longitude
	}

	const renderMarkers = (map, maps) => {
		let marker = new maps.Marker({
			position: { lat: latitude, lng: longitude },
			map,
			title
		})

		return marker
	}

	return (
		<div style={{ minHeight: 500, height: 500, width: '100%' }}>
			<GoogleMapReact
				bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
				defaultCenter={center}
				defaultZoom={zoom}
				onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
			>
			</GoogleMapReact>
		</div>
	)
}

export default GoogleMaps
