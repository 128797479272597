import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'
import './Cursos.css'
import Curso from './Curso'


function Cursos(props) {
	const { cursos } = props

	return (
		<Container fluid>
			<Row>
				<Col>
					{cursos && cursos.length ? (
						cursos?.map(curso => {
							return (
								<Curso key={curso.id} curso={curso} />
							)
						})
					) : (
						<Alert variant="info">No se especificaron cursos.</Alert>
					)}
				</Col>
			</Row>
		</Container>
	)
}

export default Cursos