import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import InputGroup from 'react-bootstrap/InputGroup'
import { toast } from 'react-toastify'
import Alert from 'react-bootstrap/Alert'
import { Offcanvas } from 'react-bootstrap'
import { PanelFooter, PanelHeader } from 'components/Panel'
import { toDisplayDate } from 'utils/date'
import api from 'api/api'
import { numberWithSeparator } from 'utils/numberWithSeparator'
import DatosPersonalesUsuario from 'components/DatosPersonalesUsuario/DatosPersonalesUsuario';
import Experiencias from 'components/profesionales/Experiencias/Experiencias';
import Cursos from 'components/profesionales/Cursos/Cursos';
import Servicios from 'components/profesionales/Servicios/Servicios';

function VerProfesional(props) {
	const { profesionalId, setQueMostrar } = props

	const [loading, setLoading] = useState(false)

	const [profesional, setProfesional] = useState(null)
	const datosPersonales = {
		id: profesional?.id,
		avatar: profesional?.avatar,
		usuario: profesional?.usuario,
		nombre: profesional?.nombre,
		apellido: profesional?.apellido,
		ci: profesional?.ci,
		telefono: profesional?.telefono,
		fechaSolicitud: profesional?.fechaSolicitud,
		fechaAprobacion: profesional?.fechaAprobacion,
		estado: profesional?.estado,
		ubicacionProfesional: profesional?.ubicacionProfesional
	}

	const refreshProfesional = async () => {
		try {
			setLoading(true)
			const { datos } = await api.get('a/profesionales/' + profesionalId)

			setProfesional(datos)
		} catch (error) {
			toast.error('Error de conexión')
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		if (!loading) {
			refreshProfesional()
		}
	}, [])

	const handleClose = () => {
		setQueMostrar('')
	}

	// render
	return (
		<Offcanvas show={true} onHide={handleClose} placement="end" scroll="true" responsive="lg">
			<PanelHeader onClick={handleClose} iconClass="fa-arrow-left" btnText="Atrás" text="Ficha de profesional" />
			<Offcanvas.Body id="ver-profesional">
				{loading ? (
					<Spinner
						animation="border"
						size="lg"
						role="status"
						aria-hidden="true"
						variant="primary"
						className="my-3"
					/>
				) : (
					<Tabs
						defaultActiveKey="datos-personales"
						className="mb-3"
					>
						<Tab eventKey="datos-personales" title="Datos personales">
							<DatosPersonalesUsuario
								key={datosPersonales.id}
								{...datosPersonales}
							/>
						</Tab>
						<Tab eventKey="experiencias" title="Experiencias">
							<Experiencias key={profesional?.id} experiencias={profesional?.experiencias} />
						</Tab>
						<Tab eventKey="cursos" title="Cursos">
							<Cursos key={profesional?.id} cursos={profesional?.cursos} />
						</Tab>
						<Tab eventKey="servicios" title="Servicios">
							{profesional?.servicios.length && (
								<Servicios key={profesional?.id} servicios={profesional?.servicios} />
							)}
						</Tab>
					</Tabs>
				)}
			</Offcanvas.Body >
			<PanelFooter>
				<Button variant="outline-primary" onClick={handleClose} className="me-2">
					Ok
				</Button>
			</PanelFooter>
		</Offcanvas >
	)
}

export default VerProfesional