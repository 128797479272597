import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'
import './Experiencias.css'
import Experiencia from './Experiencia'


function Experiencias(props) {
	const { experiencias } = props

	return (
		<Container fluid>
			<Row>
				<Col>
					{experiencias && experiencias.length ? (
						experiencias?.map(experiencia => {
							return (
								<Experiencia key={experiencia.id} experiencia={experiencia} />
							)
						})
					) : (
						<Alert variant="info">No se especificaron cursos.</Alert>
					)}
				</Col>
			</Row>
		</Container>
	)
}

export default Experiencias