import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import { toast } from 'react-toastify'

import api from 'api/api'

import './productos-con-estado.css'
import FileUpload from 'components/FileUpload/FileUpload'

function ProductosConEstado(props) {
	const [loading, setLoading] = useState(false)
	const [archivo, setArchivo] = useState()
	const [path, setPath] = useState('')

	const verificarEstado = async () => {
		const serverData = new FormData();
		serverData.append('archivo', archivo)

		try {
			const { datos, msg } = await api.post('a/reportes/ver-estado-productos', serverData)
			toast.info(msg)
			setPath(datos)
		} catch (error) {
			toast.error('Error')
		}
	}

	const updateArchivo = (files) => {
		setArchivo(prev => files[0])
	}

	// render
	return (
		<Container fluid id="control-imagenes" className="vh-100">
			<Row className="d-flex no-gutters">
				<Col xs={12} sm={6} lg={5} className="mt-2">
					<h1>Control de estado de productos</h1>
					<hr />
				</Col>
			</Row>
			<Row>
				<Col>
					<h3>Subir archivo</h3>
					<FileUpload
						updateFiles={(files) => updateArchivo(files)}
						title={"Subir archivo con SKUs"}
						accept=".xls,.xlsx"
					/>
					<Alert className="mt-3" variant="info">
						El archivo a subir debe:
						<ul>
							<li>Tener los SKUs de los productos a verificar en la primera columna.</li>
							<li>Comenzar en la fila 2 (en la 1 pueden estar los títulos).</li>
						</ul>
					</Alert>
					<Button onClick={() => verificarEstado()} disabled={!archivo}>Verificar estado</Button><br />
					<a
						href={process.env.REACT_APP_API_URL + path}
						className={`mt-2 btn btn-primary ${path === '' ? 'disabled' : ''}`}
						target="_blank"
						rel="noreferrer"
					>
						Ver resultado
					</a>
				</Col>
			</Row>
		</Container>
	);
}

export default ProductosConEstado