import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'


function SuspenderProfesional(props) {
	const { profesional, setQueMostrar, suspender } = props

	const handleClose = () => {
		setQueMostrar('')
	}
	
	return (
		<Modal show={true} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Suspender Profesional</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Alert variant="danger">
					Está a punto de suspender a <strong>{`${profesional.nombre} ${profesional.apellido}`}</strong>.
				</Alert>
				<p>¿Está seguro que desea continuar?</p>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="outline-tertiary" onClick={handleClose}>
					Cancelar
				</Button>
				<Button variant="danger" onClick={() => suspender(profesional.id)}>
					Suspender
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default SuspenderProfesional