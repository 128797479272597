import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { isLoggedIn } from './utils/general'
import SideMenu from 'components/SideMenu/SideMenu'

import NoMatch from './pages/NoMatch'
import Login from './pages/login/Login'
import Home from './pages/home/Home'
import { TestComponent } from 'pages/test'
import { Actualizaciones, ActualizacionesLog } from 'pages/actualizaciones/'
import QR from 'pages/qr/QR'
import ProductosImagenes from 'pages/productosImagenes/Productoslmagenes'
import ProductosConEstado from 'pages/productosConEstado/ProductosConEstado'
import Usuarios from 'pages/usuarios/Usuarios'
import ProfesionalesPendientes from 'pages/profesionales/ProfesionalesPendientes'
import ProfesionalesActivos from 'pages/profesionales/ProfesionalesActivos'

function App() {
	// login state
	const [loggedIn, setLoggedIn] = useState(
		isLoggedIn()
	);

	const [menuExpanded, setMenuExpanded] = useState(true)
	
	if (menuExpanded) {
		document.body.classList.add('menu-expanded')
	} else {
		document.body.classList.remove('menu-expanded')
	}

	useEffect(() => {
		setLoggedIn(isLoggedIn())
	}, []);

	// render
	return (
		<>
			<ToastContainer />
			<Router basename={process.env.PUBLIC_URL}>
					<Route render={({ location, history }) => (
						<>
							<Route exact path="/login" component={props => <Login setLoggedIn={(setLoggedIn)} history={history} />} />
							{loggedIn && <SideMenu location={location} history={history} setLoggedIn={setLoggedIn} menuExpanded={menuExpanded} setMenuExpanded={setMenuExpanded} />}
							<main className={menuExpanded ? 'section expanded' : 'section'}>
								<Switch>
									<Route exact path="/" render={() => {
										return (
											<Redirect to="/inicio" /> 
										)
									}}/>
									<Route exact path="/inicio">
										<Home />
									</Route>
									<Route exact path="/qr">
										<QR />
									</Route>
									<Route exact path="/usuarios">
										<Usuarios />
									</Route>
									<Route exact path="/profesionales/pendientes">
										<ProfesionalesPendientes />
									</Route>
									<Route exact path="/profesionales/activos">
										<ProfesionalesActivos />
									</Route>
									<Route exact path="/actualizaciones/lista">
										<Actualizaciones />
									</Route>
									<Route exact path="/actualizaciones/log">
										<ActualizacionesLog />
									</Route>
									<Route exact path="/productosImagenes">
										<ProductosImagenes />
									</Route>
									<Route exact path="/productosConEstado">
										<ProductosConEstado />
									</Route>
									<Route exact path="/test">
										<TestComponent />
									</Route>
									{loggedIn && <Route path="*" component={props => <NoMatch />} />}
								</Switch>
							</main>
						</>
					)}
					/>
					{!loggedIn && <Redirect to="/login" />}
			</Router>
		</>
	);
}

export default App
