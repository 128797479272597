// Recibe una fecha del tipo '2022-10-16 19:20:00' y devuelve según lo especificado en el config
export const toDisplayDate = (serverDate, conHora = true) => {
	const fechaConfig = {
		weekday: 'long',
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		timestyle: 'long'
	}

	const fecha = new Date(serverDate).toLocaleDateString('es-Es', fechaConfig);

	let hora = ''
	if (conHora) {
		hora = ' a las ' + new Date(serverDate).toLocaleTimeString('es-Es')
	}

	return fecha + hora
}